import React from "react";
import { Outlet } from "react-router-dom";

import NavBar from "./core/navbar/navbar";
import Content from "./core/content/content";
import Footer from "./core/footer/footer";

export default function Root() {
  return (
    <div className="App">
      <NavBar />
      <Content>
        <Outlet />
      </Content>
      <Footer />
    </div>
  );
}
