import Annoucement from './../core/card/announcement';
import Card from "./../core/card/card";
import CardsContainer from './../core/card/cards-container';
import PageHeader from "./../core/header/pageheader";

import './news.css';

export default function News() {
    const card_props = { text: "Welcome to Starseed's new website!  Starseed is a small indie game studio from Austin, TX.  After making Jimmy and the Pulsating Mass, Kasey Ozymy co-founded Starseed with the intention of building on a lot of the ideas showcased in Jimmy--namely strong, character-focused writing and turn-based RPG gameplay that's both innovative and nostalgic.  Our future projects will adhere to the core design philosophy of Jimmy but with higher production values and more creative freedom.  Thanks for checking us out, and I promise we'll have some exciting news about an upcoming project soon!" };
    return (
      <div className="news-container">
        <PageHeader props={{ className: "news-header", text: "News" }}></PageHeader>
        <CardsContainer>
          <Annoucement props={card_props} />
          <Card>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/FNJH-2jtw9Y?si=69WgK_h-UFa6nrw2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"></iframe>
          </Card>
        </CardsContainer>
      </div>
    );
  }
