import Discord from "./../core/social_icons/discord";
import TwitterFollowIcon from "./../core/social_icons/twitter-follow";
import FacebookIcon from "./../core/social_icons/facebook";
import RedditIcon from "./../core/social_icons/reddit";
import Card from "./../core/card/card";
import PageHeader from "./../core/header/pageheader";

import './contact.css';

export default function Contact() {
    return (
      <div className="contact">
          <PageHeader props={{ className: "contact-header", text: "Contact" }}></PageHeader>
          <Card props={{ className: "contact-card"}}>
            <div className="contact-container">
              <div className="contact-email-container">
                <div className="contact-email-header">
                  Email
                </div>
                <p>
                  Support: cs@starseedgames.com
                </p>
                <p>
                  Dev: dev@starseedgames.com
                </p>
                <p>
                  Media: media@starseedgames.com
                </p>
                <div className="contact-social-container">
                  <div className="contact-social-item">
                    <TwitterFollowIcon />
                  </div>
                  <div className="contact-social-item">
                    <FacebookIcon />
                  </div>
                  <div className="contact-social-item">
                    <RedditIcon />
                  </div>
                </div>
              </div>
            <div className="discord-container">
              <Discord />
            </div>
          </div>
          </Card>
      </div>
    );
  }
