

import logo from "./../../images/facebook-logo.png";

export default function FacebookIcon({ props = {}, children }) {
    return (
      <div className="full-logo-container">
        <a href="https://www.facebook.com/people/Starseed-Games/100075479036038/" className="facebook-button">
            <img src={logo} alt="facebook logo" />
        </a>
      </div>
    );
}
