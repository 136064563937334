import Card from "./../../core/card/card";

import './../games.css';

export default function GameDetails({ props = {}, children }) {
    const { title, description, itch, image, steam } = props;

    return (
        <Card>
            <div className="game-details-container">
                <div className="game-details-title">
                    {title}
                </div>
                <img src={image} alt={`${title} logo`} className="game-details-image" />
                <div className="game-details-description">
                    {description}
                </div>
                <div className="game-details-steam">
                    {steam}
                </div>
                <div className="game-details-itch">
                    {itch}
                </div>
            </div>
        </Card>
    );
  }
