import Card from "./../card/card";

import './pageheader.css';

export default function PageHeader({ props = {}, children }) {
    const { className, text } = props;
    const builtClassName = className !== undefined
        ? className + " page-header-container"
        : "page-header-container";
    return (
        <Card props={{ className: builtClassName }}>
            <header className="page-header">
                {text}
            </header>
        </Card>
    );
  }
