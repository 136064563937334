import PageHeader from "../core/header/pageheader";
import Card from "./../core/card/card";

import './aboutus.css';

export default function AboutUs() {
    return (
        <div>
            <PageHeader props={{ className: "about-us-header", text: "About Us" }}></PageHeader>
            <Card>
            <div className="about-us-container">
                Starseed is an indie game studio in Austin, TX.  Our goal is to make story-centric role-playing games that draw heavily from the best design ideas from SNES and PSX era RPGs while elevating them with exciting new gameplay ideas.
            </div>
          </Card>
        </div>
    );
  }
