import './card.css';

export default function Card({ props = {}, children }) {
    const { 
        className
    } = props;

    const calculatedClassName = className !== undefined
      ? className + " card-container"
      : "card-container";

    return (
      <div className={calculatedClassName}>
        {children}
      </div>
    );
  }
