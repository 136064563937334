import logo from "./../../images/reddit-logo.png";

export default function RedditIcon({ props = {}, children }) {
    return (
      <div className="full-logo-container">
        <a href="https://www.reddit.com/r/starseedgames/" className="reddit-button">
            <img src={logo} alt="reddit logo" />
        </a>
      </div>
    );
}
