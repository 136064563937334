import './content.css';

export default function Content({ props = {}, children }) {
    return (
      <main className="main-content-container">
        <div className="content-container">
          {children}
        </div>
      </main>
    );
}
