import { Link  } from "react-router-dom";

import Card from "./card";
import hymn from "./../../images/hymn-logo.png";
import KickstarterIcon from "./../social_icons/kickstarter-icon";

export default function Annoucement({ props = {}, children }) {

  const hymn_steam = (<iframe src="https://store.steampowered.com/widget/2165130/" title="Hymn to the Earless God Steam" frameBorder="0" width="552" height="190"></iframe>);
  const hymn_itch = (<iframe frameborder="0" src="https://itch.io/embed/2221952" title="Hymn to the Earless God Itch.io" width="552" height="167"><a href="https://housekeepinggames.itch.io/hymn-to-the-earless-god">Hymn to the Earless God by HousekeepingGames</a></iframe>);

  return (
    <Card props={{ className: "announcement-card" }}>
      <div className="announcement-container">
        <p className="announcement-text">
              Welcome to Starseed's new website!  Please support Hymn to the Earless God on Kickstarter and wishlist us on Steam!
        </p>
        <div className="annoucement-images-container">
          <div className="annoucment-hymn-link">
            <Link className="hymn-link"
            to="/games">
              <img src={hymn} alt={`Hymn to the Earless God logo`} className="game-details-image" />
            </Link>
          </div>
          <div>
            <div className="kickstarter-icon-container">
              <KickstarterIcon />
            </div>
            <div className="steam-icon-container">
              {hymn_steam}
            </div>
            <div className="itch-icon-container">
              {hymn_itch}
            </div>
          </div>
        </div>
      </div>
    </Card>
  )

    // return (
    //   <Card>
    //     <div className="announcement-container">
    //       <p className="announcement-text">
    //             Welcome to Starseed's new website!  Please support Hymn to the Earless God on Kickstarter and wishlist us on Steam!
    //       </p>
    //       <div className="annoucement-images-container">
    //         <div>
    //           <Link className="hymn-link"
    //           to="/games">
    //             <img src={hymn} alt={`Hymn to the Earless God logo`} className="game-details-image" />
    //           </Link>
    //           <iframe width="560" height="315" src="https://www.youtube.com/embed/FNJH-2jtw9Y?si=69WgK_h-UFa6nrw2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    //         </div>
    //         <div>
    //           <div className="kickstarter-icon-container">
    //             <KickstarterIcon />
    //           </div>
    //           <div className="steam-icon-container">
    //             {hymn_steam}
    //           </div>
    //           <div className="itch-icon-container">
    //             {hymn_itch}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </Card>
    // )

    // const {
    //     image, image_alt_text, text, title
    // } = props;

    // const title_component = title !== undefined
    //     ? (<div className="card-title-container">
    //          <div className="card-title">
    //             {title}
    //          </div>
    //         </div>)
    //     : null;

    // const image_component = image !== undefined
    //     ? (<div className="card-image-container">
    //          <img src={image} className="card-image" alt={image_alt_text} />
    //         </div>)
    //     : null;

    //     const text_component = text !== undefined
    //     ? (<div className="card-text-container">
    //          <div className="card-text">
    //             {text}
    //          </div>
    //         </div>)
    //     : null;

    // return (
    //   <Card props={{ className: "anncountement-card" }}>
    //     {title_component}
    //     {image_component}
    //     {text_component}
    //   </Card>
    // );
  }
