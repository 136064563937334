import logo from "./../../images/twitter-logo.png";

export default function TwitterFollowIcon({ props = {}, children }) {
    return (
      <div className="full-logo-container">
        <a href="https://twitter.com/KaseyOzymy?ref_src=twsrc%5Etfw" className="twitter-follow-button">
            <img src={logo} alt="twitter logo" />
        </a>
        {/* <a href="https://twitter.com/KaseyOzymy?ref_src=twsrc%5Etfw" className="twitter-follow-button twitter-follow-button-rendered" data-show-count="false">
            <img src={logo} alt="twitter-icon" />
        </a> */}
        {/* <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> */}
      </div>
    );
}
