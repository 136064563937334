import { Link  } from "react-router-dom";

import logo from "./../../images/full-logo.png";

export default function FullLogo({ props = {}, children }) {
    return (
      <div className="full-logo-container">
        <Link to="/">
            <img src={logo} alt="Full Logo" />
        </Link>
      </div>
    );
}
