import PageHeader from "./../core/header/pageheader";
import GameDetails from "./components/game-details";
import KickstarterIcon from "./../core/social_icons/kickstarter-icon";

import jimmy from "./../images/jimmy-logo.jpg";
import hymn from "./../images/hymn-logo.png";

import './games.css';

const jimmy_steam = (<iframe src="https://store.steampowered.com/widget/706560/" title="Jimmy and the Pulsating Mass Steam" frameBorder="0" width="646" height="190"></iframe>);
const jimmy_itch = (<iframe frameborder="0" src="https://itch.io/embed/288338" title="Jimmy and the Pulsating Mass Itch.io" width="552" height="167"><a href="https://housekeepinggames.itch.io/jimmy-and-the-pulsating-mass">Jimmy and the Pulsating Mass by HousekeepingGames</a></iframe>);
const hymn_steam = (<iframe src="https://store.steampowered.com/widget/2165130/" title="Hymn to the Earless God Steam" frameBorder="0" width="646" height="190"></iframe>);
const hymn_itch = (<iframe frameborder="0" src="https://itch.io/embed/2221952" title="Hymn to the Earless God Itch.io" width="552" height="167"><a href="https://housekeepinggames.itch.io/hymn-to-the-earless-god">Hymn to the Earless God by HousekeepingGames</a></iframe>);

const hymn_desc = (
    <div>
        <p>
        The world of Gloom balances on the edge of calamity.  The creatures devour each other.  Corruption runs rampant in the cities.  The Verrick assert their dominance.  Death knells ring from the Reiken'O village of Shen-Lan.  The last of the Sua-Roo awaits her fate.
        </p>
        <br />
        <p>
        The Great Alignment is almost upon us.
        </p>
        <br />
        <p>
        <span className="italics">Hymn to the Earless God</span> is a turn-based RPG by the developer of <span className="italics">Jimmy and the Pulsating Mass</span>.  Explore the dangerous world of Gloom, a planet inhabited by bloodthirsty tribes of insectoid creatures as one of four main characters with distinct stories
        </p>
        <br />
        <KickstarterIcon />
    </div>
);

const jimmy_desc = (
    <div>
        <p>
            Jimmy and the Pulsating Mass is a Japanese-style RPG made by a guy from Texas. It was very much influenced by SNES-era jRPGs, particularly Earthbound, Final Fantasy 5, and the Breath of Fire series, among others. It was also heavily influenced by Yume Nikki.
        </p>
        <br />
        <p>
            The game takes place in the dream of an eight-year-old boy. When an alien entity appears and threatens his family and the stability of the dream world itself, Jimmy sets out on an adventure to save his family and eradicate the threat of the Pulsating Mass. Along the way, Jimmy will have to work with his family, confront his own shortcomings, and brave the creeping nightmares of his subconscious.
        </p>
    </div>
);

export default function Games() {
    return (
        <div>
            <PageHeader props={{ className: "games-header", text: "Games" }}></PageHeader>
            <div className="games-container">
                <GameDetails props={{
                    title: "Hymn to the Earless God",
                    description: hymn_desc,
                    image: hymn,
                    itch: hymn_itch,
                    steam: hymn_steam
                }} />
                <GameDetails props={{
                    title: "Jimmy and the Pulsating Mass",
                    description: jimmy_desc,
                    image: jimmy,
                    itch: jimmy_itch,
                    steam: jimmy_steam
                }} />
            </div>
        </div>
    );
  }
