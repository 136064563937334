import { NavLink  } from "react-router-dom";

import FullLogo from "./../logo/fullLogo";

import './navbar.css';

export default function NavBar({ props = {}, children }) {
    return (
      <nav className="nav-container">
        <FullLogo />
          <div className="nav-link-container">
          <NavLink
            className="nav-link home-link"
            to="/"
          >
              Home
          </NavLink>
          <NavLink
            className="nav-link games-link"
            to="/games"
          >
            Games
          </NavLink>
          <NavLink
            className="nav-link about-us-link"
            to="/aboutus"
          >
            About Us
          </NavLink>
          <NavLink
            className="nav-link contact-link"
            to="/contact"
          >
            Contact
          </NavLink>
        </div>
      </nav>
    );
  }
