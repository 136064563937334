import { Link  } from "react-router-dom";

import FullLogo from "./../logo/fullLogo";

import './footer.css';

export default function Footer({ props = {}, children }) {
    return (
      <footer className="footer-container">
        <div className="footer-logo-container">
            <FullLogo />
        </div>
        <div className="footer-link-container">
            <Link
            className="footer-item footer-link"
            to="/aboutus"
            >
              About Us
            </Link>
            <Link
            className="footer-item footer-link"
            to="/contact"
            >
              Contact
            </Link>
        </div>
      </footer>
    );
  }
