import logo from "./../../images/kickstarter-logo.png";

export default function KickstarterIcon({ props = {}, children }) {
    return (
      <div className="kickstarter-button-container">
        <a className="kickstarter-button"
            href="https://kickstarter.com/projects/hymntotheearlessgod/hymn-to-the-earless-god">
            <img src={logo} alt="kickstarter-logo"/>
        </a>
      </div>
    );
}